import {
  EMAIL_UNSUBSCRIBE_GET_SUCCESS,
  EMAIL_UNSUBSCRIBE_GET_FAILURE,
} from '../actions/types';
import config from '../configs';

const initialState = {
  config,
  data: null,
};

const emailUnsubscribe = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_UNSUBSCRIBE_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        emailUnsubscribe: action.payload.response,
      };
    }
    case EMAIL_UNSUBSCRIBE_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default emailUnsubscribe;
