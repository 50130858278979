import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { productSearchResults as orgConfig } from 'org/configs/modules.config';
import clientConfig from 'org/configs/org.config';
import {
  PRODUCT_SEARCH_RESULTS_GET_SUCCESS, PRODUCT_SEARCH_RESULTS_GET_FAILURE,
  PRODUCT_SEARCH_RESULTS_FILTER_GET_SUCCESS, PRODUCT_SEARCH_RESULTS_FILTER_GET_FAILURE,
  PRODUCT_SEARCH_RESULTS_UPDATE_GET_SUCCESS, PRODUCT_SEARCH_RESULTS_UPDATE_GET_FAILURE,
} from '../actions/types';

export const defaultConfig = {
  defaults: {
    api: {
      params: {
        calculate_earnings: 1,
        sort_by: 'best_match',
        limit: 12,
        offset: 0,
      },
      loadOptions: {
        success: PRODUCT_SEARCH_RESULTS_GET_SUCCESS,
        failure: PRODUCT_SEARCH_RESULTS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
      filterOptions: {
        success: PRODUCT_SEARCH_RESULTS_FILTER_GET_SUCCESS,
        failure: PRODUCT_SEARCH_RESULTS_FILTER_GET_FAILURE,
        cache: { isEnabled: true },
      },
      updateOptions: {
        success: PRODUCT_SEARCH_RESULTS_UPDATE_GET_SUCCESS,
        failure: PRODUCT_SEARCH_RESULTS_UPDATE_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    sortOptions: [
      {
        sortBy: 'best_match',
        sortType: 'asc',
        viewName: 'Best match',
      },
      {
        sortBy: 'lowest_price',
        sortType: 'asc',
        viewName: 'Lowest price',
      },
      {
        sortBy: 'highest_price',
        sortType: 'asc',
        viewName: 'Highest price',
      },
    ],
    filterOptions: {
      promotionsTitle: 'Promotions',
      extraRewardsName: clientConfig.extraRewardsName,
      saleName: 'Sale',
    },
    rebateOptions: {
      hideElevationCurrency: false,
    },
    categoriesFilterTitle: '',
  },
};

export const productSearchResultsConfig = mergeModuleConfigs(defaultConfig, orgConfig);
