import React from 'react';
import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { pickFavorites as orgConfig } from 'org/configs/modules.config';
import { PICK_FAVORITES_GET_SUCCESS, PICK_FAVORITES_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        offer_tag: 'featured-favorites', // eslint-disable-line camelcase
        sort_by: 'display_order',
        fields: [
          'merchant.logoUrls',
          'merchant.id',
          'merchant.name',
        ],
      },
      options: {
        success: PICK_FAVORITES_GET_SUCCESS,
        failure: PICK_FAVORITES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    maxMerchantsToShow: 6,
    getTitle: (earnType) => <>Get alerts when your favorite stores are offering extra {earnType}<span>.</span></>,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
