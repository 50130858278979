import React from 'react';
import './MerchantRebateV2.scss';

/**
* @param {bool} hideRebatePrefix
* @param {string} prefix
* @param {string} additionalRebatePrefix
* @return {*}
*/
function rebatePrefix({
  hideRebatePrefix,
  prefix,
  additionalRebatePrefix,
  elevation,
}) {
  if (hideRebatePrefix || (!prefix && !additionalRebatePrefix) || elevation) {
    return (null);
  }

  if (additionalRebatePrefix) {
    return (
      <span className="mn_rebatePrefix">
        {`${additionalRebatePrefix} ${prefix} `}
      </span>
    );
  }

  return (
    <span className="mn_rebatePrefix">
      {`${prefix} `}
    </span>
  );
}

const buildSuffixTierNameRebate = (suffixTierName, showRebateTierName) =>
  (showRebateTierName && suffixTierName ? <span className="mn_tierName"> on {suffixTierName}</span> : '');

/**
* @param {bool} hideRebateSuffix
* @param {string} suffix
* @return {*}
*/
function rebateSuffix({
  hideRebateSuffix, suffix, suffixTierName, showRebateTierName,
}) {
  if (hideRebateSuffix || !suffix) {
    return suffixTierName ? buildSuffixTierNameRebate(suffixTierName, showRebateTierName) : (null);
  }

  return (
    <span className="mn_rebateSuffix">
      {` ${suffix}`} {buildSuffixTierNameRebate(suffixTierName, showRebateTierName)}
    </span>
  );
}

/**
*
* @param value
* @param currencyPrefixCurrent
* @param currencySuffix
* @returns {*}
*/
function rebateValue({
  value,
  currency: {
    prefix: { current: currencyPrefixCurrent },
    suffix: currencySuffix,
  },
  rebateAdditionalPrefix,
  hideNewValuePrefix,
}) {
  const newValuePrefix = hideNewValuePrefix ? '' : 'Earn';

  return (
    <span className="mn_rebateValue mn_rebateNonElevatedMerchant" data-test="merchant-rebateV2-non-elevated">
      <div className="mn_elevationOldValue">
        {rebateAdditionalPrefix || 'Shop and'}{' '}
      </div>
      <div className="mn_elevationNewValue">
        {` ${newValuePrefix} ${value} ${currencyPrefixCurrent}${currencySuffix}`}
      </div>
    </span>
  );
}

/**
*
* @param value
* @param currencyPrefixCurrent
* @param currencySuffix
* @param tieredPrefix
* @returns {*}
*/
function tieredRebateValue({
  value,
  currency: {
    prefix: { current: currencyPrefixCurrent },
    suffix: currencySuffix,
  },
  tieredPrefix,
  suffixTierName,
  rebateAdditionalPrefix,
}) {
  const tieredPrefixToShow = suffixTierName ? '' : tieredPrefix;
  return (
    <span className="mn_rebateValue mn_rebateTiered" data-test="merchant-rebateV2-tiered-non-elevation">
      <div className="mn_elevationOldValue">
        {rebateAdditionalPrefix || 'Shop and'} earn{' '}
      </div>
      <div className="mn_elevationNewValue">
        {`${tieredPrefixToShow} ${value} ${currencyPrefixCurrent}${currencySuffix}`}
      </div>
    </span>
  );
}

/**
*
* @param value
* @param originalValue
* @param hideElevationCurrency
* @param currencyPrefixCurrent
* @param currencyPrefixOriginal
* @param currencySuffix
* @returns {*}
*/
function rebateValueWithElevation({
  value,
  originalValue,
  hideElevationCurrency,
  currency: {
    prefix: { current: currencyPrefixCurrent, original: currencyPrefixOriginal },
    suffix: currencySuffix,
  },
}) {
  const oldValueSuffix = hideElevationCurrency ? '' : `${currencyPrefixOriginal}${currencySuffix}`;
  return (
    <span className="mn_rebateValue mn_rebateElevated" data-test="merchant-rebateV2-elevated">
      <div className="mn_elevationOldValue">
        {`Was ${originalValue} ${oldValueSuffix} `}
      </div>
      <div className="mn_elevationNewValue">
        {`Now ${value} ${currencyPrefixCurrent}${currencySuffix}`}
      </div>
    </span>
  );
}

/**
*
* @param value
* @param originalValue
* @param hideElevationCurrency
* @param currencyPrefixCurrent
* @param currencyPrefixOriginal
* @param currencySuffix
* @param tieredPrefix
* @returns {*}
*/
function tieredRebateValueWithElevation({
  value,
  originalValue,
  hideElevationCurrency,
  currency: {
    prefix: { current: currencyPrefixCurrent, original: currencyPrefixOriginal },
    suffix: currencySuffix,
  },
  tieredPrefix,
  suffixTierName,
}) {
  const tieredPrefixToShow = suffixTierName ? '' : tieredPrefix;
  const oldValueSuffix = hideElevationCurrency ? '' : `${currencyPrefixOriginal}${currencySuffix}`;
  return (
    <span className="mn_rebateValue mn_rebateTiered mn_rebateElevated" data-test="merchant-rebateV2-tiered-elevation">
      <div className="mn_elevationOldValue">
        {`Was ${tieredPrefixToShow} ${originalValue} ${oldValueSuffix} `}
      </div>
      <div className="mn_elevationNewValue">
        {`Now ${tieredPrefixToShow} ${value} ${currencyPrefixCurrent}${currencySuffix}`}
      </div>
    </span>
  );
}

/**
* @param {object} props
* @return {*}
*/
function rebateValueWithCurrency(props) {
  const { elevation, isTiered } = props;
  const elevatedRebate = () => (isTiered ? tieredRebateValueWithElevation(props) : rebateValueWithElevation(props));
  const rebate = () => (isTiered ? tieredRebateValue(props) : rebateValue(props));

  return (
    <span className="mn_rebateValueWithCurrency">
      { elevation ? elevatedRebate() : rebate() }
    </span>
  );
}

/**
* @param {object} props
* @return {*}
* @constructor
*/
function MerchantRebateV2(props) {
  return (
    <div className="mn_merchantRebateV2" data-test="merchant-rebateV2">
      <div className={props.showRebateInline ? 'mn_inlineRebate' : 'mn_blockRebate'}>
        {rebatePrefix(props)}
        <span className="mn_rebateValueAndSuffixWrap">{rebateValueWithCurrency(props)}</span>
        {rebateSuffix(props)}
      </div>
    </div>
  );
}

export default MerchantRebateV2;
