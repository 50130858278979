import BaseApi, { filterInvalidParams } from './base';

import memberApi, { MemberApi } from './member';

/**
 * This BonusApi class is a singleton.
 * Class instance is exported.
 * To use it import class instance and call setParams method for initialize it with parameters
 * @extends BaseApi
 */

export class BonusApi extends BaseApi {
  /** @type BonusApiCredentials */
  credentials = {
    brand_id: '',
    org_id: '',
    app_key: '',
  };

  /**
   * @typedef {object} BonusApiCredentials
   * @property {number} brand_id
   * @property {number} org_id
   * @property {string} app_id 3scale application id
   */

  /**
   * @param {object} params
   * @param {array.<string>} validParams
   * @returns {object}
   */
  static processParams(params = {}, validParams = []) {
    return filterInvalidParams(params, validParams);
  }

  setParams(params) {
    const { bonusConfig = {} } = params;
    const {
      baseUrl, timeout, headers = {}, credentials = {},
    } = bonusConfig;

    this.credentials = credentials;

    super.setParams({
      baseUrl: `${baseUrl}/`,
      timeout,
      headers: {
        ...BaseApi.defaultHeaders,
        ...headers,
      },
    });
  }

  /**
   * @param {object} options - see https://github.com/axios/axios for available options
   * @returns {Promise}
   */
  makeRequest(options = {}) {
    const requestOptions = {
      validateStatus: status => status !== 500, // Let the consumer handle non-network/fatal errors
      headers: {
        ...this.headers,
      },
      ...options,
    };

    return super.makeRequest(requestOptions);
  }

  makeAuthenticatedRequest(options = {}, requiredScope) {
    const cachedAccessToken = memberApi.getAccessTokenFromCache(requiredScope);
    const requestOptions = {
      validateStatus: status => status !== 500, // Let the consumer handle non-network/fatal errors
      headers: {
        ...this.headers,
        Authentication: cachedAccessToken,
      },
      ...options,
    };

    if (!cachedAccessToken) {
      return memberApi.authorize(requiredScope).then((accessToken) => {
        requestOptions.headers.Authentication = accessToken;
        return super.makeRequest(requestOptions);
      });
    }

    return super.makeRequest(requestOptions);
  }

  /**
   * @param {object} params
   * @return {Promise.<array.<object>>}
   */
  getBonus(params = {}) {
    return this.makeRequest(
      {
        url: '/bonus',
        params: this.injectCredentialParams(params),
      },
    );
  }

  getExpiredBonus(params = {}) {
    return this.makeAuthenticatedRequest(
      {
        url: '/bonus/expired',
        params: this.injectCredentialParams(params),
      },
      MemberApi.memberIdentifiedScope,
    );
  }

  /**
   * @param {object} params
   * @return {Promise.<array.<object>>}
   */
  getMemberRelatedBonus(params = {}) {
    return this.makeAuthenticatedRequest(
      {
        url: '/bonus',
        params: this.injectCredentialParams(params),
      },
      MemberApi.memberIdentifiedScope,
    );
  }

  /**
   *
   * @param {object} params
   * @return {object}
   */
  injectCredentialParams(params = {}) {
    const paramsWithCredentials = { ...params };

    // Inject credentials
    Object.keys(this.credentials).forEach((param) => {
      if (!Object.hasOwnProperty.call(paramsWithCredentials, param)) {
        paramsWithCredentials[param] = this.credentials[param];
      }
    });

    return paramsWithCredentials;
  }
}

export default new BonusApi();
