import dayjs from 'core/utils/dayjs';
import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { earningsSnapshot as orgConfig } from 'org/configs/modules.config';
import { EARNINGS_SNAPSHOT_GET_FAILURE, EARNINGS_SNAPSHOT_GET_SUCCESS } from '../actions/types';

const defaultConfig = {
  defaults: {
    secTitle: 'Your earnings snapshot',
    bonusTitle: 'SHOPPING',
    ctaText: 'Go to my account page',
    getMarketingProgramName: () => '',
    api: {
      params: {
        limit: 2,
        transaction_start_date: dayjs().subtract(730, 'days').format('YYYY-MM-DD'),
        transaction_state: [
          'CLIENT_POSTED',
          'ACCRUAL_PENDING',
          'CLIENT_SOFT_REJECT',
          'ACCRUAL_SENT',
        ].join(','),
      },
      options: {
        success: EARNINGS_SNAPSHOT_GET_SUCCESS,
        failure: EARNINGS_SNAPSHOT_GET_FAILURE,
        cache: { isEnabled: false, isPublishedData: false },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
