import React from 'react';
import './PremierMerchantRebate.scss';

/**
 * @param {bool} hideRebatePrefix
 * @param {string} prefix
 * @param {string} additionalRebatePrefix
 * @return {*}
 */
function rebatePrefix({
  hideRebatePrefix,
  prefix,
  earnPrefix,
  additionalRebatePrefix,
  elevation,
  isTiered,
  tiered: tieredConfig,
}) {
  const { rebateAdditionalPrefix: tieredRebateAdditionalPrefix } = tieredConfig || {};
  if (hideRebatePrefix || (!prefix && !additionalRebatePrefix && !tieredRebateAdditionalPrefix) || elevation) {
    return (null);
  }

  if (isTiered) {
    if (tieredRebateAdditionalPrefix) {
      return (
        <span className="mn_tieredAdditionalPrefix">
          {`${tieredRebateAdditionalPrefix} `}
        </span>
      );
    }

    if (!earnPrefix && !tieredRebateAdditionalPrefix) {
      return (
        <span className="mn_rebatePrefix">
          Earn&nbsp;
        </span>
      );
    }

    return (
      <span className="mn_rebatePrefix">
        {`${earnPrefix} `}
      </span>
    );
  }

  if (additionalRebatePrefix) {
    return (
      <span className="mn_rebatePrefix">
        {`${additionalRebatePrefix} ${prefix} `}
      </span>
    );
  }

  return (
    <span className="mn_rebatePrefix">
      {`${prefix} `}
    </span>
  );
}

const buildSuffixTierNameRebate = (suffixTierName, showRebateTierName) =>
  (showRebateTierName && suffixTierName ? <span className="mn_tierName"> on {suffixTierName}</span> : '');

/**
 * @param {bool} hideRebateSuffix
 * @param {string} suffix
 * @return {*}
 */
function rebateSuffix({
  hideRebateSuffix,
  suffix,
  suffixTierName,
  showRebateTierName,
}) {
  if (hideRebateSuffix || !suffix) {
    return suffixTierName ? buildSuffixTierNameRebate(suffixTierName, showRebateTierName) : (null);
  }

  return (
    <span className="mn_rebateSuffix">
      {` ${suffix}`} {buildSuffixTierNameRebate(suffixTierName, showRebateTierName)}
    </span>
  );
}

/**
 *
 * @param value
 * @param currencyPrefixCurrent
 * @param currencySuffix
 * @returns {*}
 */
function rebateValue({
  value,
  currency: {
    prefix: { current: currencyPrefixCurrent },
    suffix: currencySuffix,
  },
  isTiered,
  suffixTierName,
  tieredPrefix,
}) {
  let tieredPrefixResult = !suffixTierName ? tieredPrefix : '';
  let tieredRebateClass = '';
  if (isTiered && !suffixTierName) {
    tieredPrefixResult = <span className="mn_tieredPrefix">{tieredPrefix}&nbsp;</span>;
    tieredRebateClass = 'mn_tiered';
  }

  return (
    <span className={`mn_rebateValue ${tieredRebateClass}`}>
      {tieredPrefixResult}{value} {currencyPrefixCurrent}<span className="mn_rebateCurrency">{currencySuffix}</span>
    </span>
  );
}

/**
 *
 * @param value
 * @param originalValue
 * @param hideElevationCurrency
 * @param currencyPrefixCurrent
 * @param currencyPrefixOriginal
 * @param currencySuffix
 * @param tieredPrefix
 * @returns {*}
 */
function rebateValueWithElevation({
  value,
  originalValue,
  hideElevationCurrency,
  currency: {
    prefix: { current: currencyPrefixCurrent, original: currencyPrefixOriginal },
    suffix: currencySuffix,
  },
  tieredPrefix,
  suffixTierName,
  isTiered,
  showAdditionalElevationPrefix,
  hideRebateCurrencyPrefixInWas,
  tiered: tieredConfig,
}) {
  const { hideRebateCurrencyPrefixInWas: tieredHideRebateCurrencyPrefixInWas } = tieredConfig || {};
  let tieredPrefixResult = !suffixTierName ? tieredPrefix : '';
  let tieredRebateClass = '';
  let currencySuffixToShowInWas;

  if (isTiered && !suffixTierName) {
    tieredPrefixResult = <span className="mn_tieredPrefix">{tieredPrefix}&nbsp;</span>;
    tieredRebateClass = 'mn_tieredElevation';
  }

  if (isTiered) {
    currencySuffixToShowInWas = tieredHideRebateCurrencyPrefixInWas ? '' : currencySuffix;
  } else {
    currencySuffixToShowInWas = hideRebateCurrencyPrefixInWas ? '' : currencySuffix;
  }

  const originalCurrency = hideElevationCurrency ? '' : ` ${currencyPrefixOriginal}${currencySuffixToShowInWas}`;

  const additionalElevationPrefix =
    showAdditionalElevationPrefix && !isTiered ? <span className="mn_additionalElevationPrefix">earn</span> : '';

  /* eslint-disable max-len */
  return (
    <span className={`mn_rebateValue mn_rebateElevated ${tieredRebateClass}`}>
      <div className="mn_elevationOldValue">
        <span className="mn_elevationWasPrefix">Was</span> {tieredPrefixResult}{originalValue}{originalCurrency}
      </div>
      {' '}
      <div className="mn_elevationNewValue">
        <span className="mn_elevationNowPrefix">Now</span> {additionalElevationPrefix} {tieredPrefixResult}{value} {currencyPrefixCurrent}{currencySuffix}
      </div>
    </span>
  );
  /* eslint-enable max-len */
}

/**
 * @param {object} props
 * @return {*}
 */
function rebateValueWithCurrency(props) {
  const { elevation } = props;

  return (
    <span className="mn_rebateValueWithCurrency">
      {elevation ? rebateValueWithElevation(props) : rebateValue(props)}
    </span>
  );
}

/**
 * @param {object} props
 * @return {*}
 */
function rebateValueWithNoRebate(props) {
  return (
    <span className="mn_merchantRebate">
      <span className="mn_rebateValue">{props.value}</span>
    </span>
  );
}

/**
 * @param {object} props
 * @return {*}
 * @constructor
 */
function PremierMerchantRebate(props) {
  const { isDeactivated } = props;

  return (
    <div className="mn_premierMerchantRebate">
      <div className={props.showRebateInline ? 'mn_inlineRebate' : 'mn_blockRebate'}>
        {rebatePrefix(props)}
        <span className="mn_rebateValueAndSuffixWrap">
          {isDeactivated ? (rebateValueWithNoRebate(props)) : rebateValueWithCurrency(props)}
        </span>
        {rebateSuffix(props)}
      </div>
    </div>
  );
}

export default PremierMerchantRebate;
