// Action events
import { searchFormGaActionEvents } from 'core/modules/SearchForm/analytics';
import {
  hybridSearchFormGaActionEvents, hybridSearchFormClickAndHoverEvents,
} from 'core/modules/HybridSearchForm/analytics';
import { emailOptInTriggerGaActionEvents } from 'core/modules/EmailOptInTrigger/analytics';
import { emailOptInSuccessModalGaActionEvents } from 'core/modules/Modal/components/EmailOptInSuccessModal/analytics';
import { dynamicRewardsTabContentGaActionEvents } from 'core/modules/DynamicRewardsTabContent/analytics';

import { curatedActiveMerchantsGaActionEvents } from 'core/modules/CuratedActiveMerchants/analytics';
import { relatedCategoryFeaturedStoresGaActionEvents } from 'core/modules/RelatedCategoryFeaturedStores/analytics';
import { navBannerGaActionEvents, navBannerClickAndHoverEvents } from 'core/modules/Navigation/NavBanner/analytics';
import {
  navCategoriesGaActionEvents, navCategoriesClickAndHoverEvents,
} from 'core/modules/Navigation/NavCategories/analytics';

import {
  forYouOffersTabActionEvents,
  forYouOffersTabClickAndHoverEvents,
} from 'core/modules/ForYou/components/OffersTab/analytics';
import {
  forYouBecauseYouShoppedActionEvents,
  forYouBecauseYouShoppedClickAndHoverEvents,
} from 'core/modules/ForYou/components/BecauseYouShoppedForYou/analytics';
import {
  sponsoredTextOffersActionEvents,
  sponsoredTextOffersClickAndHoverEvents,
} from 'core/modules/SponsoredTextOffers/analytics';

// Click and hover events
import { favoriteIconClickAndHoverEvents } from 'core/modules/FavoriteIcon/analytics';
import { tabbedContentClickAndHoverEvents } from 'core/modules/Partials/Tabs/analytics';
import { topEarnClickAndHoverEvents } from 'core/modules/TopEarn/analytics';
import { buyOnlinePickupInstoreClickAndHoverEvents } from 'core/modules/BuyOnlinePickupInstore/analytics';
import { becauseYouShoppedClickAndHoverEvents } from 'core/modules/BecauseYouShopped/analytics';
import { recentlyShoppedClickAndHoverEvents } from 'core/modules/RecentlyShopped/analytics';
import { recommendedStoresClickAndHoverEvents } from 'core/modules/RecommendedStores/analytics';
import { loginLinkClickAndHoverEvents } from 'core/modules/LoginLink/analytics';
import { featuredFavoritesClickAndHoverEvents } from 'core/modules/FeaturedFavorites/analytics';
import { storeBundlesClickAndHoverEvents } from 'core/modules/StoreBundles/analytics';
import { dynamicMerchantsClickAndHoverEvents } from 'core/modules/DynamicMerchants/analytics';
import { featuredOffers150x150ClickAndHoverEvents } from 'core/modules/FeaturedOffers150x150/analytics';
import { navigationClickAndHoverEvents } from 'core/modules/Navigation/Nav/analytics';
import { orderInquiryModalClickAndHoverEvents } from 'core/modules/Modal/components/OrderInquiryModal/analytics';
import { navSeasonalFlyoutClickAndHoverEvents } from 'core/modules/Navigation/NavSeasonalFlyout/analytics';
import { favoritesLandingClickAndHoverEvents } from 'core//modules/FavoritesLanding/analytics';
import {
  emailOptInSubscribeModalClickAndHoverEvents,
} from 'core/modules/Modal/components/EmailOptInSubscribeModal/analytics';

// Click, hover and action events
import { instoreAdOffersSectionActionEvents } from 'core/modules/InstoreAdOffers/analytics';
import {
  topStoresClickAndHoverEvents,
  topStoresGaActionEvents,
} from 'core/modules/TopStores/analytics';
import {
  featuredOffersLogoGaActionEvents,
  featuredOffersLogoClickAndHoverEvents,
} from 'core/modules/FeaturedOffersLogo/analytics';
import {
  marketingMerchantsHomeGaActionEvents,
  marketingMerchantsHomeClickAndHoverEvents,
} from 'core/modules/MarketingMerchantsHome/analytics';
import {
  featuredStoresModalGaActionEvents,
  featuredStoresClickAndHoverEvents,
} from 'core/modules/Modal/components/FeaturedStoresModal/analytics';
import {
  onboardingGaActionEvents,
  onboardingClickAndHoverEvents,
} from 'core/modules/Onboarding/analytics';
import {
  seasonalFeaturedGaActionEvents,
  seasonalFeaturedClickAndHoverEvents,
} from 'core/modules/SeasonalFeatured/analytics';

import {
  letterboxTOFGaActionEvents,
  letterboxTOFClickAndHoverEvents,
} from 'core/modules/LetterboxTOF/analytics';

import {
  merchantOfferModalGaActionEvents,
  merchantOfferModalClickAndHoverEvents,
} from 'core/modules/Modal/components/MerchantOfferModal/analytics';

import {
  offerTableGaActionEvents,
  offerTableClickAndHoverEvents,
} from 'core/modules/OfferTable/analytics';

import {
  quickLinksTrayGaActionEvents,
  quickLinksTrayClickAndHoverEvents,
} from 'core/modules/QuickLinksTray/analytics';

import {
  infoBarGaActionEvents,
  infoBarClickAndHoverEvents,
} from 'core/modules/InfoBar/analytics';

import {
  featuredDealsGaActionEvents,
  myAccountFeaturedDealsGaActionEvents,
  featuredDealsClickAndHoverEvents,
  myAccountFeaturedDealsClickAndHoverEvents,
} from 'core/modules/FeaturedDeals/analytics';

import {
  recentStoresGaActionEvents,
  recentStoresClickAndHoverEvents,
} from 'core/modules/RecentStores/analytics';

import {
  popularOffersGaActionEvents,
  popularOffersClickAndHoverEvents,
} from 'core/modules/PopularOffers/analytics';

import {
  featuredOffers502x272ClickAndHoverEvents,
  featuredOffers502x272GaActionEvents,
} from 'core/modules/FeaturedOffers502x272/analytics';

import {
  merchantExperienceGaActionEvents,
  merchantExperienceClickAndHoverEvents,
} from 'core/modules/MerchantExperience/analytics';

import {
  bonusOffers300x250GaActionEvents,
  bonusOffers300x250ClickAndHoverEvents,
} from 'core/modules/BonusOffers300x250/analytics';

import {
  bonusOffersFlexPageGaActionEvents,
  bonusOffersFlexPageClickAndHoverEvents,
} from 'core/modules/BonusOffersFlexPage/analytics';

import {
  marketingBannerGaActionEvents,
  marketingBannerClickAndHoverEvents,
} from 'core/modules/MarketingBanner/analytics';

import {
  buttonLandingClickAndHoverEvents,
} from 'core/modules/Button/ButtonLanding/analytics';

import { buttonUninstallGaActionEvents } from 'core/modules/Button/ButtonUninstall/analytics';

import {
  extraRewardsDealsGaActionEvents,
  extraRewardsDealsClickAndHoverEvents,
} from 'core/modules/ExtraRewardsDeals/analytics';

import {
  contactUsDashboardGaActionEvents,
  contactUsDashboardClickAndHoverEvents,
} from 'core/modules/ContactUs/Dashboard/analytics';

import { instoreHIWClickAndHoverEvents } from 'core/modules/Instore/HiwSection/analytics';

import {
  instoreOffersSectionClickAndHoverEvents,
  instoreOffersSectionActionEvents,
} from 'core/modules/Instore/OffersSection/analytics';

import {
  instoreOffersLinkingClickAndHoverEvents,
  instoreOffersLinkingActionEvents,
} from 'core/modules/Instore/CardLinkOffer/analytics';

import {
  instoreMapActionEvents,
  instoreMapClickAndHoverEvents,
} from 'core/modules/Instore/Map/analytics';

import { instoreAddCardActionEvents } from 'core/modules/Modal/components/InstoreModals/AddNewCardModal/analytics';

import {
  instoreSMSNumberActionEvents,
  instoreSMSNumberClickAndHoverEvents,
} from 'core/modules/Modal/components/InstoreModals/SMSNumberModal/analytics';

import {
  instoreHIWTypesActionEvents,
  instoreHIWTypesClickAndHoverEvents,
} from 'core/modules/Modal/components/InstoreHiwModal/analytics';

import {
  bonusTrackerGaActionEvents,
  bonusTrackerClickAndHoverEvents,
} from 'core/modules/BonusTracker/analytics';

import {
  similarStoresGaActionEvents,
  similarStoresClickAndHoverEvents,
} from 'core/modules/SimilarStores/analytics';

import {
  recentStoresFlyoutGaActionEvents,
  recentStoresFlyoutClickAndHoverEvents,
} from 'core/modules/RecentStoresFlyout/analytics';

import { adblockGaActionEvents } from 'core/modules/AdBlockWarning/analytics';

import {
  headerOnboardingCongratulationsGaActionEvents,
} from 'core/modules/Header/HeaderOnboardingCongratulations/analytics';

import {
  favoriteStoresGaActionEvents,
  favoriteStoresClickAndHoverEvents,
} from 'core/modules/FavoriteStores/analytics';

import {
stickyFooterGaActionEvents,
stickyFooterClickAndHoverEvents,
} from 'core/modules/StickyFooter/analytics';

import {
  pickFavoritesGaActionEvents,
  pickFavoritesClickAndHoverEvents,
  } from 'core/modules/PickFavorites/analytics';

import {
  exploreCategoriesGaActionEvents,
  exploreCategoriesClickAndHoverEvents,
} from 'core/modules/ExploreCategories/analytics';

import {
  earningsSnapshotGaActionEvents,
  earningsSnapshotClickAndHoverEvents,
} from 'core/modules/EarningsSnapshot/analytics';

import {
  emailOptInSectionGaActionEvents,
} from 'core/modules/EmailOptIn/analytics';

import {
  howItWorksGaActionEvents,
  howItWorksClickEvents,
} from 'core/modules/HowItWorks/analytics';

import {
  testimonialsGaActionEvents,
} from 'core/modules/Testimonials/analytics';

import {
  personalizedTopOffersGaActionEvents,
  personalizedTopOffersClickAndHoverEvents,
} from 'core/modules/PersonalizedTopOffers/analytics';

import {
  trendingAndSeasonalOffersGaActionEvents,
  trendingAndSeasonalOffersClickAndHoverEvents,
} from 'core/modules/TrendingAndSeasonalOffers/analytics';

import {
  buttonScrollingModalGaActionEvents,
  buttonScrollingModalClickAndHoverEvents,
} from 'core/modules/Modal/components/ButtonScrollingModal/analytics';

import {
  buttonBenefitsGaActionEvents,
  buttonBenefitsClickEvents,
} from 'core/modules/HomeButtonBenefits/analytics';

import {
  transactionAdUnitGaActionEvents,
  transactionAdUnitClickAndHoverEvents,
} from 'core/modules/Profile/components/TransactionAdUnit/analytics';

import {
  firstFavoriteModalGaActionEvents,
  firstFavoriteModalClickAndHoverEvents,
} from 'core/modules/Modal/components/FirstFavoriteModal/analytics';

import {
  stickyCtaBarGaActionEvents,
  stickyCtaBarClickAndHoverEvents,
} from 'core/modules/StickyCtaBar/analytics';

import {
  hybridSearchResultsGaActionEvents,
} from 'core/modules/HybridSearchResults/analytics';

import {
  hybridStoresOfferModalGaActionEvents,
  hybridStoresOfferModalClickAndHoverEvents,
} from 'core/modules/Modal/components/HybridStoresOfferModal/analytics';

import {
  productDetailsModalGaActionEvents,
  productDetailsModalClickAndHoverEvents,
} from 'core/modules/Modal/components/ProductDetailsModal/analytics';

import {
  hybridStoresGaActionEvents,
  hybridStoresClickAndHoverEvents,
} from 'core/modules/HybridSearchResults/components/HybridStores/analytics';

import {
  hybridProductsClickAndHoverEvents,
} from 'core/modules/HybridSearchResults/components/HybridProducts/analytics';

import {
  productSearchResultsGaActionEvents,
  productSearchResultsClickAndHoverEvents,
} from 'core/modules/ProductSearchResults/analytics';

import {
  hybridCouponsClickAndHoverEvents,
} from 'core/modules/HybridSearchResults/components/HybridCoupons/analytics';

import {
  couponSearchResultsGaActionEvents,
  couponSearchResultsClickAndHoverEvents,
} from 'core/modules/CouponSearchResults/analytics';

import {
  safariButtonInstallTutorialGaActionEvents,
  safariButtonInstallTutorialClickAndHoverEvents,
} from 'core/modules/SafariButtonInstallTutorial/analytics';

// Global events
import {
  externalLinkClickAndHoverEvents,
  forcedloginClickAndHoverEvents,
} from 'core/utils/analytics/ga-globalEvents';

/**
 * If you have core redux actions that need to fire corresponding GA events, add an object key
 * for that action
 *
 * @type {Object.<string, GAEvent>}
 */
const coreGaActionEvents = {
  ...topStoresGaActionEvents,
  ...featuredOffersLogoGaActionEvents,
  ...marketingMerchantsHomeGaActionEvents,
  ...searchFormGaActionEvents,
  ...hybridSearchFormGaActionEvents,
  ...merchantOfferModalGaActionEvents,
  ...featuredStoresModalGaActionEvents,
  ...seasonalFeaturedGaActionEvents,
  ...emailOptInTriggerGaActionEvents,
  ...emailOptInSuccessModalGaActionEvents,
  ...letterboxTOFGaActionEvents,
  ...onboardingGaActionEvents,
  ...dynamicRewardsTabContentGaActionEvents,
  ...offerTableGaActionEvents,
  ...quickLinksTrayGaActionEvents,
  ...infoBarGaActionEvents,
  ...featuredDealsGaActionEvents,
  ...myAccountFeaturedDealsGaActionEvents,
  ...recentStoresGaActionEvents,
  ...popularOffersGaActionEvents,
  ...featuredOffers502x272GaActionEvents,
  ...merchantExperienceGaActionEvents,
  ...bonusOffers300x250GaActionEvents,
  ...bonusOffersFlexPageGaActionEvents,
  ...marketingBannerGaActionEvents,
  ...buttonUninstallGaActionEvents,
  ...extraRewardsDealsGaActionEvents,
  ...contactUsDashboardGaActionEvents,
  ...instoreOffersSectionActionEvents,
  ...instoreOffersLinkingActionEvents,
  ...instoreMapActionEvents,
  ...instoreAddCardActionEvents,
  ...instoreSMSNumberActionEvents,
  ...instoreHIWTypesActionEvents,
  ...similarStoresGaActionEvents,
  ...curatedActiveMerchantsGaActionEvents,
  ...relatedCategoryFeaturedStoresGaActionEvents,
  ...forYouOffersTabActionEvents,
  ...forYouBecauseYouShoppedActionEvents,
  ...instoreAdOffersSectionActionEvents,
  ...bonusTrackerGaActionEvents,
  ...sponsoredTextOffersActionEvents,
  ...adblockGaActionEvents,
  ...navBannerGaActionEvents,
  ...navCategoriesGaActionEvents,
  ...headerOnboardingCongratulationsGaActionEvents,
  ...recentStoresFlyoutGaActionEvents,
  ...favoriteStoresGaActionEvents,
  ...stickyFooterGaActionEvents,
  ...pickFavoritesGaActionEvents,
  ...exploreCategoriesGaActionEvents,
  ...earningsSnapshotGaActionEvents,
  ...emailOptInSectionGaActionEvents,
  ...howItWorksGaActionEvents,
  ...testimonialsGaActionEvents,
  ...personalizedTopOffersGaActionEvents,
  ...trendingAndSeasonalOffersGaActionEvents,
  ...buttonScrollingModalGaActionEvents,
  ...buttonBenefitsGaActionEvents,
  ...transactionAdUnitGaActionEvents,
  ...firstFavoriteModalGaActionEvents,
  ...stickyCtaBarGaActionEvents,
  ...hybridSearchResultsGaActionEvents,
  ...hybridStoresOfferModalGaActionEvents,
  ...productDetailsModalGaActionEvents,
  ...hybridStoresGaActionEvents,
  ...productSearchResultsGaActionEvents,
  ...couponSearchResultsGaActionEvents,
  ...safariButtonInstallTutorialGaActionEvents,
};

/**
 * See ./analytics.js for an explanation of the format
 * @type {EventTrackerList}
 */
export const coreGaClickAndHoverEvents = [
  ...favoriteIconClickAndHoverEvents,
  ...trendingAndSeasonalOffersClickAndHoverEvents,
  ...tabbedContentClickAndHoverEvents,
  ...topEarnClickAndHoverEvents,
  ...hybridSearchFormClickAndHoverEvents,
  ...topStoresClickAndHoverEvents,
  ...transactionAdUnitClickAndHoverEvents,
  ...featuredOffersLogoClickAndHoverEvents,
  ...marketingMerchantsHomeClickAndHoverEvents,
  ...buyOnlinePickupInstoreClickAndHoverEvents,
  ...becauseYouShoppedClickAndHoverEvents,
  ...recentlyShoppedClickAndHoverEvents,
  ...recommendedStoresClickAndHoverEvents,
  ...featuredStoresClickAndHoverEvents,
  ...loginLinkClickAndHoverEvents,
  ...seasonalFeaturedClickAndHoverEvents,
  ...featuredFavoritesClickAndHoverEvents,
  ...emailOptInSubscribeModalClickAndHoverEvents,
  ...letterboxTOFClickAndHoverEvents,
  ...storeBundlesClickAndHoverEvents,
  ...externalLinkClickAndHoverEvents,
  ...forcedloginClickAndHoverEvents,
  ...onboardingClickAndHoverEvents,
  ...merchantOfferModalClickAndHoverEvents,
  ...featuredOffers150x150ClickAndHoverEvents,
  ...dynamicMerchantsClickAndHoverEvents,
  ...featuredOffers502x272ClickAndHoverEvents,
  ...offerTableClickAndHoverEvents,
  ...quickLinksTrayClickAndHoverEvents,
  ...infoBarClickAndHoverEvents,
  ...featuredDealsClickAndHoverEvents,
  ...myAccountFeaturedDealsClickAndHoverEvents,
  ...recentStoresClickAndHoverEvents,
  ...navigationClickAndHoverEvents,
  ...popularOffersClickAndHoverEvents,
  ...merchantExperienceClickAndHoverEvents,
  ...bonusOffers300x250ClickAndHoverEvents,
  ...bonusOffersFlexPageClickAndHoverEvents,
  ...buttonLandingClickAndHoverEvents,
  ...marketingBannerClickAndHoverEvents,
  ...extraRewardsDealsClickAndHoverEvents,
  ...contactUsDashboardClickAndHoverEvents,
  ...orderInquiryModalClickAndHoverEvents,
  ...instoreHIWClickAndHoverEvents,
  ...instoreOffersSectionClickAndHoverEvents,
  ...instoreOffersLinkingClickAndHoverEvents,
  ...instoreMapClickAndHoverEvents,
  ...instoreSMSNumberClickAndHoverEvents,
  ...instoreHIWTypesClickAndHoverEvents,
  ...similarStoresClickAndHoverEvents,
  ...bonusTrackerClickAndHoverEvents,
  ...forYouOffersTabClickAndHoverEvents,
  ...forYouBecauseYouShoppedClickAndHoverEvents,
  ...sponsoredTextOffersClickAndHoverEvents,
  ...navBannerClickAndHoverEvents,
  ...navCategoriesClickAndHoverEvents,
  ...navSeasonalFlyoutClickAndHoverEvents,
  ...recentStoresFlyoutClickAndHoverEvents,
  ...favoriteStoresClickAndHoverEvents,
  ...stickyFooterClickAndHoverEvents,
  ...pickFavoritesClickAndHoverEvents,
  ...exploreCategoriesClickAndHoverEvents,
  ...earningsSnapshotClickAndHoverEvents,
  ...personalizedTopOffersClickAndHoverEvents,
  ...buttonScrollingModalClickAndHoverEvents,
  ...howItWorksClickEvents,
  ...buttonBenefitsClickEvents,
  ...firstFavoriteModalClickAndHoverEvents,
  ...stickyCtaBarClickAndHoverEvents,
  ...hybridStoresOfferModalClickAndHoverEvents,
  ...productDetailsModalClickAndHoverEvents,
  ...hybridStoresClickAndHoverEvents,
  ...hybridProductsClickAndHoverEvents,
  ...productSearchResultsClickAndHoverEvents,
  ...hybridCouponsClickAndHoverEvents,
  ...couponSearchResultsClickAndHoverEvents,
  ...favoritesLandingClickAndHoverEvents,
  ...safariButtonInstallTutorialClickAndHoverEvents,
];

export default coreGaActionEvents;
