import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { popularOffers as orgConfig } from 'org/configs/modules.config';
import { POPULAR_OFFERS_GET_SUCCESS, POPULAR_OFFERS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        sort_by: 'random',
        content_type_id: 19,
        fields: [
          'id',
          'assets',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.name',
          'clickUrl',
        ],
        section_id: getId('popular-offers-300x250'),
      },
      options: {
        success: POPULAR_OFFERS_GET_SUCCESS,
        failure: POPULAR_OFFERS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    sectionTitle: 'Popular offers',
    useAddToFavorite: false,
    shouldIncludeBonusOffers: true,
    useSliderCounterOnMediaQueriesOnly: [],
    includeBonusOffersOnMediaQueriesOnly: [],
    getShouldShowViewAllLink: (isMobileMQ) => (!isMobileMQ),
    checkShouldUseCarousel: () => (false),
    tagHelper: () => {},
    rebateOptions: {
      additionalRebatePrefix: 'Plus,',
      tiered: {
        additionalRebatePrefix: 'Plus,',
      },
    },
    slick: {
      infinite: false,
      speed: 800,
      swipeToSlide: true,
      swipe: true,
      dots: false,
      arrows: false,
      variableWidth: true,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 1420,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1048,
          settings: {
            slidesToShow: 2,
          },
        },
       {
         breakpoint: 768,
         settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          },
        },
      ],
    },
  },
  h: {
    sectionTitle: 'Trending offers',
    shouldIncludeBonusOffers: false,
    maxNumberOfBonusOffersInXXL: 2,
    useSliderCounterOnMediaQueriesOnly: ['XS', 'S'],
    includeBonusOffersOnMediaQueriesOnly: ['XS', 'S', 'M', 'L', 'XL'],
    checkShouldUseCarousel: (activeMQ) => (['XS', 'S', 'M', 'L', 'XL'].includes(activeMQ)),
    api: {
      params: {
        offer_tag: 'trending-offers',
      },
    },
  },
  sp: {
    bonusOffersLimit: 2,
    api: {
      params: {
        content_group_id: 11041,
      },
    },
  },
  fs: {
    bonusOffersLimit: 2,
    api: {
      params: {
        content_group_id: 11039,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
