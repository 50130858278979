import { getPageName } from 'core/utils/analytics/pageInfo';
import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { BONUS_TRACKER_VIEW } from '../actions/types';

const getCategory = () => {
  if (getPageName() === 'foryou') {
    return 'Bonus Tracker: Offers for you';
  }

  return 'Account Page Bonus Tracker';
};

export const bonusTrackerGaActionEvents = {
  [BONUS_TRACKER_VIEW]: ({ action, label }) => ({
      category: getCategory(),
      action,
      label,
  }),
};

export const bonusTrackerClickAndHoverEvents = [
  {
    selector: '.mn_bonusTracker .mn_bonusButtonCTA',
    click: {
      category: getCategory(),
      action: 'CTA Click',
    },
    label: (element) => {
      const offerTextSectionElement = findAncestorByClassName(element, 'mn_bonusTracker') || {};
      const { label } = offerTextSectionElement.getElementsByClassName('mn_textSection')[0].dataset;

      return label;
    },
  },
  {
    selector: '.mn_bonusTracker .mn_termAndConditions',
    click: {
      category: getCategory(),
      action: 'View Terms Click',
    },
    label: (element) => {
      const offerTextSectionElement = findAncestorByClassName(element, 'mn_bonusTracker') || {};
      const { label } = offerTextSectionElement.getElementsByClassName('mn_textSection')[0].dataset;

      return label;
    },
  },
];
