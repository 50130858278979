import React from 'react';
import FavoriteIcon from 'core/modules/FavoriteIcon/FavoriteIcon';
import MerchantRebateV2 from 'core/modules/MerchantRebateV2/MerchantRebateV2';
import Tile from 'core/modules/Tile/Tile';
import ExtraRewardsPill from 'core/modules/ExtraRewardsPill/ExtraRewardsPill';
import orgConfig from 'org/configs/org.config';

import './MerchantTile.scss';

function MerchantTile({
  merchant, useAddToFavorite, rebateOptions, useSmallerIcon, label,
}) {
  const {
    id, name, logoUrls, rebate,
  } = merchant;

  const { extraRewardsName } = orgConfig;

  if (!logoUrls || !rebate) {
    return null;
  }

  return (
    <div
      className={`mn_merchantTile mn_favoriteItemWrap ${rebate.isExtraRewards ? 'mn_extraRewardsMerchant' : ''}`}
      data-merchant-id={id}
      data-merchant-name={name}
    >
      <Tile>
        <div className="mn_merchantTileInner">
          {useAddToFavorite && <FavoriteIcon merchantId={id} merchantName={name} />}
          <img src={useSmallerIcon ? logoUrls._88x31 : logoUrls._120x60} alt={name} />
          <ExtraRewardsPill rebate={rebate} label={label || extraRewardsName} />
        </div>
        <MerchantRebateV2 {...rebate} {...rebateOptions} hideRebatePrefix hideElevationCurrency />
      </Tile>
    </div>
  );
}

MerchantTile.defaultProps = {
  useAddToFavorite: true,
  rebateOptions: {},
};

export default MerchantTile;
