/* eslint-disable camelcase */
import bowser from 'bowser';
import { throttle } from 'lodash';
import LocalStorageCache from 'core/utils/cache/localStorage';

const localStorageCache = new LocalStorageCache();

// Insider custom events could be triggered from our code BEFORE the Insider is initialized.
// We don't want to lose such events so storing them in `postponedEvents` array and triggering after initializing.
const postponedEvents = [];

const checkIsInsiderEnabled = () => {
  const { app: { features: { insider } } } = window.initialState;

  return insider.isEnabled;
};

const checkInisiderIsInitialized = () => window.Insider?.initialized;

const waitForInsiderLoad = () => (
  new Promise((resolve) => {
    const MAX_RETRIES = 30;
    const INTERVAL_IN_MS = 100;
    let retryCounter = 0;

    const isInsiderEnabled = checkIsInsiderEnabled();

    if (!isInsiderEnabled) {
      resolve(false);
      return;
    }

    if (checkInisiderIsInitialized()) {
      resolve(true);
      return;
    }

    const intervalId = setInterval(() => {
      if (checkInisiderIsInitialized()) {
        resolve(true);
        clearInterval(intervalId);
        return;
      }

      if (retryCounter > MAX_RETRIES) {
        resolve(false);
        clearInterval(intervalId);
        return;
      }

      retryCounter += 1;
    }, INTERVAL_IN_MS);
  })
);

const track = (event) => {
  const isInsiderEnabled = checkIsInsiderEnabled();
  const isInsiderInitialized = checkInisiderIsInitialized();

  if (!isInsiderEnabled) {
    return;
  }

  if (!isInsiderInitialized) {
    postponedEvents.push(event);
  }

  window.Insider?.track('events', event);
};

const getBrowserInfo = () => {
  try {
    const { browser, platform } = bowser.parse(window.navigator.userAgent);
    const platformName = `${platform.type.charAt(0).toUpperCase()}${platform.type.slice(1)}`;
    const browserName = browser.name.replace(/Microsoft/ig, '').trim();

    return { platformName, browserName };
  } catch (e) {
    return { platformName: '', browserName: '' };
  }
};

const addScrollEventListener = () => {
  const throttledCheckScroll = throttle(checkScroll, 250);
  const breakPoints = [
    { value: 0.25, isScrolled: false },
    { value: 0.5, isScrolled: false },
    { value: 0.75, isScrolled: false },
  ];

  window.addEventListener('scroll', throttledCheckScroll);

  const triggerInsiderEvent = (value) => {
    track([{
      event_name: 'Scroll',
      event_params: {
        custom: {
          value,
        },
      },
    }]);
  };

  function checkScroll() {
    const maxPageScrollHeight = document.body.scrollHeight - document.documentElement.clientHeight;
    breakPoints.forEach((item) => {
      if (window.scrollY >= maxPageScrollHeight * item.value && !item.isScrolled) {
        item.isScrolled = true;
        triggerInsiderEvent(item.value);
      }
    });

    const isAllBreakPointsScrolled = breakPoints.every(({ isScrolled }) => isScrolled);
    if (isAllBreakPointsScrolled) {
      window.removeEventListener('scroll', throttledCheckScroll);
    }
  }
};

const trackInsiderLoginEvent = () => {
  const IS_INSIDER_LOGIN_EVENT_SENT_KEY = 'isInsiderLoginEventSent';
  const isInsiderLoginEventSent = localStorageCache.getItem(IS_INSIDER_LOGIN_EVENT_SENT_KEY);
  const { user: { isIdentified, firstTimeVisit } } = window.initialState;

  if (isIdentified && !firstTimeVisit && !isInsiderLoginEventSent) {
    track([{
      event_name: 'Login v1',
      event_params: {
        custom: {
          cuid: window.insider_object.user.uuid,
          cuidType: 'recipientId',
        },
      },
    }]);

    localStorageCache.setItem(IS_INSIDER_LOGIN_EVENT_SENT_KEY, true);
  }
};

const trackInsiderRegistrationEvent = () => {
  const IS_INSIDER_REGISTRATION_EVENT_SENT_KEY = 'isInsiderRegistrationEventSent';
  const isInsiderRegistrationEventSent = localStorageCache.getItem(IS_INSIDER_REGISTRATION_EVENT_SENT_KEY);
  const { user: { isIdentified, firstTimeVisit } } = window.initialState;

  if (isIdentified && firstTimeVisit && !isInsiderRegistrationEventSent) {
    track([{
      event_name: 'Signup v1',
      event_params: {
        custom: {
          cuid: window.insider_object.user.uuid,
          cuidType: 'recipientId',
        },
      },
    }]);

    localStorageCache.setItem(IS_INSIDER_REGISTRATION_EVENT_SENT_KEY, true);
  }
};

export const initiateInsider = () => {
  waitForInsiderLoad().then((isLoaded) => {
    if (!isLoaded) return;

    trackInsiderLoginEvent();
    trackInsiderRegistrationEvent();

    document.readyState === 'complete'
      ? addScrollEventListener()
      : window.addEventListener('load', () => addScrollEventListener());

    // Trigger postponed events when Insider is finally initialized
    postponedEvents.forEach((event) => {
      window.Insider?.track('events', event);
    });
  });
};

export const trackInsiderKeywordSearchEvent = (keywords) => {
  track([{
    event_name: 'Keyword-search-v1',
    event_params: {
      custom: {
        keywords,
      },
    },
  }]);
};

export const trackInsiderEmailOptinEvent = (emailPopupCookie) => {
  const { seenCount, emailGlobalOptOut } = emailPopupCookie;

  track([{
    event_name: 'Email OptIn',
    event_params: {
      custom: {
        value: emailGlobalOptOut,
        seenCount,
      },
    },
  }]);
};

export const trackInsiderFavoriteEvent = ({ merchantName, merchantId }) => {
  track([{
    event_name: 'Favorite',
    event_params: {
      custom: {
        merchantName,
        gmid: merchantId,
      },
    },
  }]);
};

export const trackInsiderButtonUninstallEvent = () => {
  track([{
    event_name: 'Button Uninstall',
    event_params: {
      custom: {
        browser: getBrowserInfo().browserName,
      },
    },
  }]);
};
