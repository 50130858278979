import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getPageName, getDisplayPageName } from 'core/utils/analytics/pageInfo';

const getLabel = (element) => {
  const dynamicMerchant = findAncestorByClassName(element, 'mn_dynamicMerchant') || {};
  const { merchantName, logoTreatment, viewType } = dynamicMerchant.dataset;
  const dynamicMerchants = findAncestorByClassName(element, 'mn_dynamicMerchants') || {};
  const { title } = dynamicMerchants.dataset;

  return `${merchantName} | ${title} | ${logoTreatment}-${viewType} layout`;
};

export const dynamicMerchantsClickAndHoverEvents = [
  {
    selector: '.mn_dynamicMerchant .mn_favoriteIcon',
    click: {
      category: `${getDisplayPageName()} | logo`,
      action: element => `${element.classList.contains('mn_favorited') ? 'Remove' : 'Add'} favorite`,
    },
    label: getLabel,
    custom: element => ({
      merchant: element.dataset.merchantName,
    }),
  },
  {
    selector: '.mn_dynamicMerchant .mn_dynamicMerchantLink',
    click: {
      category: `${getDisplayPageName()} | logo`,
      action: 'Click',
    },
    label: getLabel,
  },
  {
    selector: '.mn_dynamicMerchants .mn_dynamicMerchantsSortOptions span[role="button"]',
    click: {
      category: () => getPageName(),
      action: (element) => {
        const dynamicMerchants = findAncestorByClassName(element, 'mn_dynamicMerchants') || {};
        const { title } = dynamicMerchants.dataset;

        return `Sort Stores|${title}`;
      },
    },
    label: ({ dataset }) => dataset.sort_by,
    value: ({ dataset }) => parseInt(dataset.merchant_count, 10),
  },
];
