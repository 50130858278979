import ocapi from 'core/sagas/ocapi';
import member from 'core/sagas/member';
import bonus from 'core/sagas/bonus';
import server from 'core/sagas/server';
import gsp from 'core/sagas/gsp';
import offerOverlayLinkSaga from 'core/modules/OfferOverlayLink/sagas';
import productOverlayLinkSaga from 'core/modules/ProductOverlayLink/sagas';
import onlineInStoreOfferOverlayLinkSaga from 'core/modules/OnlineInStoreOfferOverlayLink/sagas';
import merchantExperienceSaga from 'core/modules/MerchantExperience/sagas';
import emailUnsubscribeSaga from 'core/modules/EmailUnsubscribe/sagas';
import appSaga from 'core/modules/App/sagas';
import inquiryOrderSaga from 'core/modules/ContactUs/Dashboard/components/InquiryOrder/sagas';
import recentStoresFlyoutSaga from 'core/modules/RecentStoresFlyout/sagas';
import buttonScrollingModalSaga from 'core/modules/Modal/components/ButtonScrollingModal/sagas';
import deletedMemberModalSaga from 'core/modules/Modal/components/DeletedMemberModal/sagas';

const coreSagas = {
  ocapi,
  member,
  bonus,
  server,
  gsp,
  offerOverlayLinkSaga,
  onlineInStoreOfferOverlayLinkSaga,
  merchantExperienceSaga,
  emailUnsubscribeSaga,
  appSaga,
  inquiryOrderSaga,
  recentStoresFlyoutSaga,
  buttonScrollingModalSaga,
  productOverlayLinkSaga,
  deletedMemberModalSaga,
};

// Exporting an object instead of an actual saga allows org-level overrides of core sagas
export default coreSagas;
