import React from 'react';
import './MerchantRebate.scss';

/**
 * @param {bool} hideRebatePrefix
 * @param {string} prefix
 * @param {string} additionalRebatePrefix
 * @return {*}
 */
function rebatePrefix({ hideRebatePrefix, prefix, additionalRebatePrefix }) {
  if (hideRebatePrefix || (!prefix && !additionalRebatePrefix)) {
    return (null);
  }

  if (additionalRebatePrefix) {
    return (
      <span className="mn_rebatePrefix">
        {`${additionalRebatePrefix} ${prefix} `}
      </span>
    );
  }

  return (
    <span className="mn_rebatePrefix">
      {`${prefix} `}
    </span>
  );
}

const buildSuffixTierNameRebate = (suffixTierName, showRebateTierName) =>
  (showRebateTierName && suffixTierName ? <span className="mn_tierName"> on {suffixTierName}</span> : '');

/**
 * @param {bool} hideRebateSuffix
 * @param {string} suffix
 * @return {*}
 */
function rebateSuffix({
  hideRebateSuffix, suffix, suffixTierName, showRebateTierName,
}) {
  if (hideRebateSuffix || !suffix) {
    return suffixTierName ? buildSuffixTierNameRebate(suffixTierName, showRebateTierName) : (null);
  }

  return (
    <span className="mn_rebateSuffix">
      {` ${suffix}`} {buildSuffixTierNameRebate(suffixTierName, showRebateTierName)}
    </span>
  );
}

/**
 *
 * @param value
 * @param currencyPrefixCurrent
 * @param currencySuffix
 * @param tieredPrefix
 * @param hideRebatePrefix
 * @returns {*}
 */
function rebateValue({
  value,
  currency: {
    prefix: { current: currencyPrefixCurrent },
    suffix: currencySuffix,
  },
  tieredPrefix,
  hideRebatePrefix,
}) {
  // prefix already includes tieredPrefix so if it's not hidden, don't show tieredPrefix
  let tieredPrefixResult = tieredPrefix;
  if (!hideRebatePrefix) {
    tieredPrefixResult = '';
  } else if (tieredPrefix && tieredPrefix.length > 0) {
    tieredPrefixResult = `${tieredPrefix} `;
  }

  return (
    <span className="mn_rebateValue">
      {tieredPrefixResult}{value} {currencyPrefixCurrent}<span className="mn_rebateCurrency">{currencySuffix}</span>
    </span>
  );
}

/**
 *
 * @param value
 * @param originalValue
 * @param currencyPrefixCurrent
 * @param currencySuffix
 * @param tieredPrefix
 * @param hideRebatePrefix
 * @returns {*}
 */
function rebateValueWithElevation({
  value,
  originalValue,
  currency: {
    prefix: { current: currencyPrefixCurrent },
    suffix: currencySuffix,
  },
  tieredPrefix,
  hideRebatePrefix,
}) {
  // prefix already includes tieredPrefix so if it's not hidden, don't show tieredPrefix
  let tieredPrefixResult = tieredPrefix;
  if (!hideRebatePrefix) {
    tieredPrefixResult = '';
  } else if (tieredPrefix && tieredPrefix.length > 0) {
    tieredPrefixResult = `${tieredPrefix} `;
  }

  return (
    <span className="mn_rebateValue">
      {tieredPrefixResult}<span className="mn_elevationOldValue">{originalValue}</span>
      {' '}
      <span className="mn_elevationNewValue">
        {`${value} `}
        {currencyPrefixCurrent}
        <span className="mn_rebateCurrency">{currencySuffix}</span>
      </span>
    </span>
  );
}

/**
 * @param {object} props
 * @return {*}
 */
function rebateValueWithCurrency(props) {
  const { elevation } = props;

  return (
    <span className="mn_rebateValueWithCurrency">
      {elevation ? rebateValueWithElevation(props) : rebateValue(props)}
    </span>
  );
}

/**
 * @param {object} props
 * @return {*}
 */
function rebateValueWithNoRebate(props) {
  return (
    <span className="mn_merchantRebate">
      <span className="mn_rebateValue">{props.value}</span>
    </span>
  );
}

/**
 * @param {object} props
 * @return {*}
 * @constructor
 */
function MerchantRebate(props) {
  const { isDeactivated } = props;

  return (
    <span className="mn_merchantRebate">
      {rebatePrefix(props)}
      <span className="mn_rebateValueAndSuffixWrap">
        {isDeactivated ? (rebateValueWithNoRebate(props)) : rebateValueWithCurrency(props)}
      </span>
      {rebateSuffix(props)}
    </span>
  );
}

MerchantRebate.defaultProps = {
  value: '',
  currency: {
    prefix: { current: '' },
    suffix: '',
  },
  tieredPrefix: '',
  hideRebatePrefix: false,
};

export default MerchantRebate;
