import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { hybridStores as orgConfig } from 'org/configs/modules.config';

import {
  HYBRID_STORES_MERCHANTS_GET_SUCCESS, HYBRID_STORES_MERCHANTS_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        with_instore: 1,
      },
      options: {
        success: HYBRID_STORES_MERCHANTS_GET_SUCCESS,
        failure: HYBRID_STORES_MERCHANTS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    secTitle: 'Stores',
    viewAllLinkCTAText: 'View all stores',
    slick: {
      arrows: false,
      centerMode: false,
      infinite: false,
      dots: false,
    },
    isMultiMerchantResult: false,
    maxMerchants: 8,
    label: 'Extra',
    rebateOptions: {
      hideRebatePrefix: false,
      hideElevationCurrency: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
