import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { emailUnsubscribe as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    globalStreamTypeText: 'newsletters',
    globalUnsubscribeDescription: '',
    confirmDescription: '',
    secTitle: '',
    successTitle: '',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
