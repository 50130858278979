import axios from 'axios';
import dayjs from 'core/utils/dayjs';
import { min, range } from 'lodash';
import BaseApi, { filterInvalidParams } from './base';
import LocalStorageCache from '../cache/localStorage';

/**
 * This MemberApi class is a singleton.
 * Class instance is exported.
 * To use it import class instance and call setParams method for initialize it with parameters
 * @extends BaseApi
 */

export class MemberApi extends BaseApi {
  /** @type {string} */
  static credentialsCacheKeyPrefix = 'memberApi.credentials';

  /** @type {number} */
  static memberAnonymousScope = 0;

  /** @type {number} */
  static memberIdentifiedScope = 1;

  /** @type {number} */
  static memberAuthenticatedScope = 2;

  /**
   * @param {number} scope
   * @return {string}
   */
  static getCredentialsCacheKey(scope) {
    return `${MemberApi.credentialsCacheKeyPrefix}::${scope}`;
  }

  /**
   * @param {object} params
   * @param {array.<string>} validParams
   * @returns {object}
   */
  static processParams(params = {}, validParams = []) {
    return filterInvalidParams(params, validParams);
  }

  setParams(params) {
    const { memberConfig = {}, brandId } = params;
    const {
      baseUrl, basePath, timeout, headers = {},
    } = memberConfig;

    this.brandId = brandId;

    super.setParams({
      baseUrl: `${baseUrl}/${basePath}`,
      timeout,
      headers: {
        ...BaseApi.defaultHeaders,
        ...headers,
      },
    });
  }

  /** @type {LocalStorageCache} */
  cache = new LocalStorageCache();

  /**
   * Extract an access token from cache based on required scope level
   * @param {number} scope
   * @return {string}
   */
  getAccessTokenFromCache(scope = MemberApi.memberAuthenticatedScope) {
    const anonScope = MemberApi.memberAnonymousScope;
    const minScope = scope >= anonScope ? scope : anonScope;

    /* If somebody tries to pass a scope less than 0 or more than 2,
       we limit the min range to viable options */
    const minRange = min([minScope, MemberApi.memberAuthenticatedScope]);
    const maxRange = MemberApi.memberAuthenticatedScope;

    // maxRange + 1 as lodash range goes up to but not including right argument
    const availableRange = range(minRange, maxRange + 1);

    // Loop through available credentials to find the most appropriate accessToken
    return availableRange.reduce((accessToken, cachedScope) => {
      const cacheKey = MemberApi.getCredentialsCacheKey(cachedScope);
      const cachedAccessToken = this.cache.getItem(cacheKey);

      if (!accessToken && cachedAccessToken) {
        accessToken = cachedAccessToken; // eslint-disable-line no-param-reassign
      }

      return accessToken;
    }, null);
  }

  /**
   * @param {string} accessToken
   * @param {number} scope
   * @param {number} ttlSeconds
   */
  writeAccessTokenToCache({ accessToken, scope, ttlSeconds } = {}) {
    if (!accessToken) {
      return;
    }

    const cacheKey = MemberApi.getCredentialsCacheKey(scope);
    const expireInSeconds = ttlSeconds ? Number(ttlSeconds) : 0;
    const cacheExpiration = dayjs()
      .add(expireInSeconds, 'seconds')
      .valueOf();

    this.cache.setItem(cacheKey, accessToken, {
      expirationTimestamp: cacheExpiration,
    });
  }

  /**
   * @param {number} requiredScope
   * @return {Promise<string|null>}
   */
  authorize(requiredScope) {
    const config = {
      params: {
        brandId: this.brandId,
      },
      headers: this.headers,
    };

    if (this.timeout) {
      config.params.timeout = this.timeout;
    }

    return axios
      .get('/services/member/authorize.php', config)
      .then(({ data = {} }) => {
        if (!data.accessToken) {
          console.log('Authorize failed: ', data.message || 'Unknown error');
          return null;
        }

        if (data.scope >= requiredScope) {
          // Cache the access token data
          this.writeAccessTokenToCache(data);

          return data.accessToken;
        }

        console.log(`Authorize failed due to reduced scope: Requested ${requiredScope}, received ${data.scope}`);

        return null;
      })
      .catch((e) => {
        console.log('Authorize failed: ', e.message || 'Unknown error');
        return null;
      });
  }

  /**
   * @param {object} options - see https://github.com/axios/axios for available options
   * @param {number} requiredScope
   * @returns {Promise}
   */
  makeRequest(options = {}, requiredScope) {
    const cachedAccessToken = this.getAccessTokenFromCache(requiredScope);
    const requestOptions = {
      validateStatus: status => status !== 500, // Let the consumer handle non-network/fatal errors
      headers: {
        ...this.headers,
        Authentication: cachedAccessToken,
      },
      ...options,
    };

    if (!cachedAccessToken) {
      return this.authorize(requiredScope).then((accessToken) => {
        requestOptions.headers.Authentication = accessToken;
        return super.makeRequest(requestOptions);
      });
    }

    return super.makeRequest(requestOptions);
  }

  /**
   * @param {object} params
   * @return {Promise.<array.<object>>}
   */
  getFavorites(params = {}) {
    return this.makeRequest(
      {
        url: '/member/favorites',
        params: MemberApi.processParams(params),
      },
      MemberApi.memberIdentifiedScope,
    );
  }

  /**
   * Favorites a merchant for the current user
   *
   * @param {number} merchantId
   * @param {object} params
   * @return {Promise<boolean>}
   */
  addFavoriteById(merchantId, params = {}) {
    return this.makeRequest(
      {
        url: '/member/favorites',
        method: 'post',
        data: { merchantId },
        params: MemberApi.processParams(params),
      },
      MemberApi.memberIdentifiedScope,
    );
  }

  /**
   * Un-Favorites a merchant for the current user
   * @param {number} merchantId
   * @param {object} params
   * @return {Promise<boolean>}
   */
  deleteFavoriteById(merchantId, params = {}) {
    return this.makeRequest(
      {
        url: '/member/favorites',
        method: 'delete',
        data: { merchantId },
        params: MemberApi.processParams(params),
      },
      MemberApi.memberIdentifiedScope,
    );
  }

  /**
   * @param {string} componentName
   * @param {object} metadata
   * @param {object} params
   * @return {Promise<boolean>}
   */
  postUIComponentMetadata(componentName, metadata = [], params = {}) {
    return this.makeRequest(
      {
        url: `/member/ui-component/${componentName}/metadata`,
        method: 'post',
        data: metadata,
        params: MemberApi.processParams(params),
      },
      MemberApi.memberIdentifiedScope,
    );
  }

  postInstoreCardLink(offerId, paymentCard, params = {}) {
    return this.makeRequest(
      {
        url: '/member/linked-offers',
        method: 'post',
        data: {
          offerId,
          paymentCard,
        },
        params: MemberApi.processParams(params),
      },
      MemberApi.memberIdentifiedScope,
    );
  }

  postInstoreMultiOfferCardLink(paymentCard, params = {}) {
    return this.makeRequest(
      {
        url: '/member/linked-offers',
        method: 'post',
        data: {
          paymentCard,
        },
        params: MemberApi.processParams(params),
      },
      MemberApi.memberIdentifiedScope,
    );
  }

  postInstoreRegisterToken(params = {}) {
    return this.makeRequest(
      {
        url: '/member/payment-cards/register-token',
        method: 'POST',
        params: MemberApi.processParams(params),
      },
      MemberApi.memberAuthenticatedScope,
    );
  }

  getInstorePaymentCards(params = {}) {
    return this.makeRequest(
      {
        url: '/member/payment-cards',
        method: 'GET',
        params: MemberApi.processParams(params),
      },
      MemberApi.memberAuthenticatedScope,
    );
  }

  getInstorePaymentDeleteCards(cardId, params = {}) {
    return this.makeRequest(
      {
        url: `/member/payment-cards?cardId=${cardId}`,
        method: 'Delete',
        data: { cardId },
        params: MemberApi.processParams(params),
      },
      MemberApi.memberAuthenticatedScope,
    );
  }

  postInstoreMobileNumber(mobileNumber, params = {}) {
    return this.makeRequest(
      {
        url: '/member/in-store-sms-registration',
        method: 'POST',
        data: {
          mobileNumber,
          optInValue: true,
          params: MemberApi.processParams(params),
        },
      },
      MemberApi.memberAuthenticatedScope,
    );
  }

  setInstoreAddCardOnbordingProperty(params = {}) {
    return this.makeRequest(
      {
        url: '/member/in-store-payment-onboarding',
        method: 'POST',
        data: {
          params: MemberApi.processParams(params),
        },
      },
      MemberApi.memberAuthenticatedScope,
    );
  }
}

export default new MemberApi();
