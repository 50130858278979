import { takeEvery, put } from 'redux-saga/effects';
import {
  EMAIL_UNSUBSCRIBE_FROM_SMARTY_GET_SUCCESS, EMAIL_UNSUBSCRIBE_GET_SUCCESS, EMAIL_UNSUBSCRIBE_GET_FAILURE,
} from '../actions/types';

function* parseEmailUnsubscribeDataAndPutResponseAction() {
  const emailUnsubscribe = window.emailUnsubscribe ? window.emailUnsubscribe : null;
  if (emailUnsubscribe) {
    yield put({
      type: EMAIL_UNSUBSCRIBE_GET_SUCCESS,
      payload: { response: emailUnsubscribe },
    });
  } else {
    yield put({
      type: EMAIL_UNSUBSCRIBE_GET_FAILURE,
    });
  }
}

export default function* emailUnsubscribeSaga() {
  yield takeEvery(EMAIL_UNSUBSCRIBE_FROM_SMARTY_GET_SUCCESS, parseEmailUnsubscribeDataAndPutResponseAction);
}
