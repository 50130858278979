import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { accountSummary as orgConfig } from 'org/configs/modules.config';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        cache: { isEnabled: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
